var commonService = require('./common');

class Ws {}
let ws = new Ws();
module.exports = ws;
commonService.mergeEventEmitterPrototype(ws, ['Error', 'Close', 'Login', 'Logout',
    'JoinGroup', 'NotifyJoinGroup',
    'LeaveGroup', 'NotifyLeaveGroup',
    'UpdateGroup', 'NotifyUpdateGroup',
    'UpdateMe', 'UpdateProfileImg', 'UpdateStat', 'UpdateState', 'UpdateMyExt', 'NotifyUpdateMember', 'NotifyUpdateState', 'NotifyClose',
    'GetGroupMembers', 'GetMember', 'GetMyGroups', 'GetMyExt', 'GetIceServers', 'SendMessageToMember', 'NotifyReceiveMessageFromMember',
    'CreateMyCallHistory', 'UpdateMyCallHistory', 'GetMyCallHistories', 'GetMyCallHistory', 'ChangePassword'
]);

let wssUri;

let webSocket;

function sendRTCMessage(handlerType, messageType, message, to, messageUid) {
    var msg = { messageType: messageType, handlerType: handlerType, message: message, to: to, messageUid: messageUid };
    if (webSocket && webSocket.readyState === 1) {
        webSocket.send(JSON.stringify(msg));
        // console.log('webSocket.send : ', msg);
    }
};

function postState() {
    let msg = { state: ws.me.state };
    sendRTCMessage('server', 'updateCurrentState', JSON.stringify(msg), '0', Date.now());
}

function connectWebSocket(id, password, extra, isForce) {
    var reqUri = wssUri + '?IsForce=' + isForce + '&MemberId=' + encodeURIComponent(id);
    if (password !== null && password !== undefined) {
        reqUri += '&Password=' + encodeURIComponent(password);
    }
    if (extra !== null && extra !== undefined) {
        reqUri += '&Extra=' + encodeURIComponent(extra);
    }
    if (ws.sign !== null && ws.sign !== undefined) {
        reqUri += '&Sign=' + encodeURIComponent(ws.sign);
    }
    webSocket = new WebSocket(reqUri);
    setWebSocketEvent(webSocket);

    return webSocket;
}

let postStateTimer;

function setWebSocketEvent(webSocket) {
    // 웹소켓의 오픈 이벤트 발생 시 실행 되는 함수

    let opened = false;
    webSocket.onopen = function(event) {
        opened = true;
        ws.getIceServers();
        ws.trigger('UpdateState', [ws.me]);
    };
    // 상대로부터 메세지가 왔을 때 실행 되는 함수
    webSocket.onmessage = function(event) {
        processMessage(commonService.jsonParse(event.data));
        //  console.log('onmessage', commonService.jsonParse(event.data))
    };
    // 에러 발생 시
    webSocket.onerror = function(error) {
        if (opened === false) {
            ws.trigger('Login', [false]); // TODO 오픈 안 된 상태에서 에러는 로그인 실패로 처리. 에러코드 알아 낼 수 있는 방법은 정녕 없을까?
        } else {
            ws.trigger('Error', [error]);
        }
    };
    // 웹소켓 닫힐 때
    webSocket.onclose = function(event) {
        if (postStateTimer) {
            clearInterval(postStateTimer);
            postStateTimer = null;
        }
        if (opened === true) {
            ws.trigger('Close', [event.code, event.reason]);
            // ws.trigger('Logout', [event.code, event.reason]);
        }
    };
}

function processLoginResponse(msg) {
    let me = commonService.jsonParse(msg.message);
    setMe(me);
    postState();
    postStateTimer = setInterval(function() {
        postState();
    }, 30000);

}

function processChangePasswordResponse(msg) {
    let result = commonService.jsonParse(msg.message);
    if (result.resultCode === 200) {
        ws.trigger('ChangePassword', [true, result.resultCode]);
    } else {
        ws.trigger('ChangePassword', [false, result.resultCode]);
    }
}

function processJoinGroupResponse(msg) {
    let group = commonService.jsonParse(msg.message);
    if (group.resultCode) {
        ws.trigger('JoinGroup', [false, group.resultCode]);
    } else {
        addJoinGroup(group);
        getGroupMembers(group.id);
        ws.trigger('JoinGroup', [true, group]);
    }
}

function processJoinGroupEvent(msg) {
    let groupId = msg.from;
    let member = commonService.jsonParse(msg.message);
    setGroupMember(groupId, member);
    ws.trigger('NotifyJoinGroup', [groupId, member]);
}

function processLeaveGroupResponse(msg) {
    let group = commonService.jsonParse(msg.message);
    if (group.resultCode) {
        ws.trigger('LeaveGroup', [false, group.resultCode]);
    } else {
        removeJoinGroup(group);
        ws.trigger('LeaveGroup', [true, group]);
    }
}

function processLeaveGroupEvent(msg) {
    let groupId = msg.from;
    let member = commonService.jsonParse(msg.message);
    removeGroupMember(groupId, member);
    ws.trigger('NotifyLeaveGroup', [groupId, member]);
}

function processUpdateGroupResponse(msg) {
    let group = commonService.jsonParse(msg.message);
    if (group.resultCode) {
        ws.trigger('UpdateGroup', [false, group.resultCode]);
    } else {
        updateGroupInfo(group, false);
        ws.trigger('UpdateGroup', [true, group]);
    }
}

function processUpdateGroupEvent(msg) {
    let group = commonService.jsonParse(msg.message);
    updateGroupInfo(group, false);
    ws.trigger('NotifyUpdateGroup', [group]);
}

function processGetGroupMembersResponse(msg) {
    // 자동 요청이 존재 하기 때문에 messageUid 있을 경우만 이벤트 발생함.
    let groupId = msg.from;
    let members = commonService.jsonParse(msg.message);
    if (members.resultCode) {
        if (msg.messageUid) {
            ws.trigger('GetGroupMembers', [false, members.resultCode]);
        }
    } else {
        members = arrayToDic(members);
        setGroupMembers(groupId, members);
        if (msg.messageUid) {
            ws.trigger('GetGroupMembers', [true, { groupId: groupId, members: members }]);
        }
    }
}

function processGetMemberResponse(msg) {
    let member = commonService.jsonParse(msg.message);
    if (member.resultCode) {
        ws.trigger('GetMember', [false, member.resultCode]);
    } else {
        ws.trigger('GetMember', [true, member]);
    }
}

function processGetMyExtResponse(msg) {
    let ext = commonService.jsonParse(msg.message);
    if (ext.resultCode) {
        ws.trigger('GetMyExt', [false, ext.resultCode]);
    } else {
        ws.trigger('GetMyExt', [true, ext]);
    }
}

function processCreateMyCallHistoryResponse(msg) {
    let ext = commonService.jsonParse(msg.message);

    if (ext.resultCode) {
        ws.trigger('CreateMyCallHistory', [false, ext.resultCode]);
    } else {
        ws.trigger('CreateMyCallHistory', [true, ext]);
    }
}

function processUpdateMyCallHistoryResponse(msg) {
    let ext = commonService.jsonParse(msg.message);
    if (ext.resultCode) {
        ws.trigger('UpdateMyCallHistory', [false, ext.resultCode]);
    } else {
        ws.trigger('UpdateMyCallHistory', [true, ext]);
    }
}

function processGetMyCallHistoriesResponse(msg) {
    let ext = commonService.jsonParse(msg.message);
    if (ext.resultCode) {
        ws.trigger('GetMyCallHistories', [false, ext.resultCode]);
    } else {
        ws.trigger('GetMyCallHistories', [true, ext]);
    }
}

function processGetMyCallHistoryResponse(msg) {
    let ext = commonService.jsonParse(msg.message);
    if (ext.resultCode) {
        ws.trigger('GetMyCallHistory', [false, ext.resultCode]);
    } else {
        ws.trigger('GetMyCallHistory', [true, ext]);
    }
}

function processUpdateCurrentStateResponse(msg) {
    let result = commonService.jsonParse(msg.message);
    if (result.resultCode !== 200) {
        console.warn('UpdateCurrentState Fail : ', result);
        ws.trigger('Error', [result]);
    } else {
        if (result.unReadMessages) {
            let unReadMessages = result.unReadMessages; // commonService.jsonParse(result.unReadMessages);
            for (var prop in unReadMessages) {
                if (unReadMessages.hasOwnProperty(prop)) {
                    processMessage(unReadMessages[prop]);
                }
            }
        }
    }
}

function processUpdateStateEvent(msg) {
    let groupId = msg.from;
    let member = commonService.jsonParse(msg.message);
    updateGroupMemberState(groupId, member);
    // TODO 자신의 상태가 online이 아닐 경우 현재 상태를 서버로 재전송해야 함
    ws.trigger('NotifyUpdateState', [groupId, member]);
}

function processUpdateProfileImgResponse(msg) {
    let me = commonService.jsonParse(msg.message);
    if (me.resultCode) {
        ws.trigger('UpdateProfileImg', [false, me.resultCode]);
    } else {
        setMe(me);
        ws.trigger('UpdateProfileImg', [true, me]);
    }
}

function processUpdateStatResponse(msg) {
    let stat = commonService.jsonParse(msg.message);
    if (stat.resultCode) {
        ws.trigger('UpdateStat', [false, stat.resultCode]);
    } else {
        ws.trigger('UpdateStat', [true, { statId: stat.id }]);
    }
}

function processUpdateMeResponse(msg) {
    let me = commonService.jsonParse(msg.message);
    if (me.resultCode) {
        ws.trigger('UpdateMe', [false, me.resultCode]);
    } else {
        setMe(me);
        ws.trigger('UpdateMe', [true, me]);
    }
}

function processUpdateMyExtResponse(msg) {
    let ext = commonService.jsonParse(msg.message);
    if (ext.resultCode && ext.resultCode !== 200) {
        ws.trigger('UpdateMyExt', [false, ext.resultCode]);
    } else {
        ws.trigger('UpdateMyExt', [true, ext.resultCode]);
    }
}

function processGetIceServersResponse(msg) {
    let servers = commonService.jsonParse(msg.message);
    if (servers.resultCode) {
        ws.trigger('GetIceServers', [false, servers.resultCode]);
    } else {
        ws.trigger('GetIceServers', [true, servers]);
    }
}

function processGetMyGroupsResponse(msg) {
    let groups = commonService.jsonParse(msg.message);
    if (groups.resultCode) {
        ws.trigger('GetMyGroups', [false, groups.resultCode]);
    } else {
        for (let index = 0; index < groups.length; index++) {
            let group = groups[index];
            updateGroupInfo(group, true);
        }
        ws.trigger('GetMyGroups', [true, groups]);
    }
}

function processUpdateMemberEvent(msg) {
    let groupId = msg.from;
    let member = commonService.jsonParse(msg.message);
    setGroupMember(groupId, member);
    ws.trigger('NotifyUpdateMember', [groupId, member]);
}

function processCloseResponse(msg) {
    let dupl = commonService.jsonParse(msg.message);
    console.warn('1008 New Connection Occured', dupl);
    // 굳이 여기서 할 필요 없어 보임. close 이벤트해서 처리 하는 것이 나아 보임.
    // ws.trigger('NotifyClose', [1008, dupl]);
}

function processRelayResponse(msg) {
    let message = commonService.jsonParse(msg.message);
    if (message.resultCode !== 200) {
        ws.trigger('SendMessageToMember', [false, message.resultCode, msg.messageUid]);
    } else {
        ws.trigger('SendMessageToMember', [true, message.resultCode, msg.messageUid]);
    }
}

function processReceiveMessageFromMemberEvent(msg) {
    ws.trigger('NotifyReceiveMessageFromMember', [msg.from, msg.messageType, msg.message]);
}

function processMessage(msg) {
    try {
        if (msg && msg.handlerType && msg.messageType && !(msg.from === undefined || msg.from === null)) {
            if (msg.from && typeof msg.from !== 'string') {
                msg.from = msg.from.toString();
            }
            if (msg.messageType === 'updateCurrentState' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processUpdateCurrentStateResponse(msg);
            } else if (msg.messageType === 'updateState' && msg.handlerType === 'group' && msg.message) {
                processUpdateStateEvent(msg);
            } else if (msg.messageType === 'changePassword' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processChangePasswordResponse(msg);
            } else if (msg.messageType === 'login' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processLoginResponse(msg);
            } else if (msg.messageType === 'joinGroup' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processJoinGroupResponse(msg);
            } else if (msg.messageType === 'joinGroup' && msg.handlerType === 'group' && msg.message) {
                processJoinGroupEvent(msg);
            } else if (msg.messageType === 'leaveGroup' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processLeaveGroupResponse(msg);
            } else if (msg.messageType === 'leaveGroup' && msg.handlerType === 'group' && msg.message) {
                processLeaveGroupEvent(msg);
            } else if (msg.messageType === 'updateGroup' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processUpdateGroupResponse(msg);
            } else if (msg.messageType === 'updateGroup' && msg.handlerType === 'group' && msg.message) {
                processUpdateGroupEvent(msg);
            } else if (msg.messageType === 'updateMe' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processUpdateMeResponse(msg);
            } else if (msg.messageType === 'updateProfileImg' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processUpdateProfileImgResponse(msg);
            } else if (msg.messageType === 'updateStat' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processUpdateStatResponse(msg);
            } else if (msg.messageType === 'updateMember' && msg.handlerType === 'group' && msg.message) {
                processUpdateMemberEvent(msg);
            } else if (msg.messageType === 'updateMyExt' && msg.handlerType === 'server' && msg.message) {
                processUpdateMyExtResponse(msg);
            } else if (msg.messageType === 'getIceServers' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processGetIceServersResponse(msg);
            } else if (msg.messageType === 'getMyGroups' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processGetMyGroupsResponse(msg);
            } else if (msg.messageType === 'getGroupMembers' && msg.handlerType === 'group' && msg.message) {
                processGetGroupMembersResponse(msg);
            } else if (msg.messageType === 'getMember' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processGetMemberResponse(msg);
            } else if (msg.messageType === 'getMyExt' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processGetMyExtResponse(msg);
            } else if (msg.messageType === 'createMyCallHistory' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processCreateMyCallHistoryResponse(msg);
            } else if (msg.messageType === 'updateMyCallHistory' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processUpdateMyCallHistoryResponse(msg);
            } else if (msg.messageType === 'getMyCallHistories' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processGetMyCallHistoriesResponse(msg);
            } else if (msg.messageType === 'getMyCallHistory' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processGetMyCallHistoryResponse(msg);
            } else if (msg.messageType === 'close' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processCloseResponse(msg);
            } else if (msg.messageType === 'relay' && msg.handlerType === 'server' && msg.from === '0' && msg.message) {
                processRelayResponse(msg);
            } else if (msg.messageType && msg.handlerType === 'member' && msg.from) {
                processReceiveMessageFromMemberEvent(msg);
            } else {
                console.warn('message not processed : ', msg);
            }
        } else {
            console.warn('message structure mismatch : ', msg);
        }
    } catch (error) {
        console.error(error);
    }
}

function arrayToDic(idArray) {
    let result = {};
    for (let index = 0; index < idArray.length; index++) {
        let item = idArray[index];
        result[item.id] = item;
    }
    return result;
}

function setMe(me) {
    ws.me.id = me.id || '';
    ws.me.memberId = me.memberId || '';
    ws.me.extra = me.extra || '';
    ws.me.state = me.state || '';
    ws.me.os = me.os || '';
    ws.me.modelNo = me.modelNo || '';
    ws.me.name = me.name || '';
    ws.me.section = me.section || '';
    ws.me.position = me.position || '';
    ws.me.email = me.email || '';
    ws.me.prfImgURL = me.prfImgURL || '';
    ws.me.isWorker = me.isWorker || '';
    ws.me.isCaller = me.isCaller || '';
}

function addJoinGroup(group) {
    ws.joinGroups[group.id] = group;
}

function removeJoinGroup(group) {
    delete ws.joinGroups[group.id];
}
// 그룹 정보를 주어진 값으로 변경, addable true일 경우 그룹 없을 경우 추가 함.
function updateGroupInfo(groupnew, addable) {
    let group = ws.joinGroups[groupnew.id];
    if (group) {
        group.name = groupnew.name;
        group.extra = groupnew.extra;
    } else {
        if (addable === true) {
            addJoinGroup(groupnew);
        } else {
            console.warn('not join group', groupnew);
        }
    }
}

function setGroupMember(groupId, member) {
    let group = ws.joinGroups[groupId];
    if (group) {
        if (!group.members) {
            group.members = {};
        }
        group.members[member.id] = member;
    } else {
        console.warn('not join group', groupId, member);
    }
}

function removeGroupMember(groupId, member) {
    let group = ws.joinGroups[groupId];
    if (group) {
        if (!group.members) {
            group.members = {};
        }
        delete group.members[member.id];
    } else {
        console.warn('not join group', groupId, member);
    }
}

function updateGroupMemberState(groupId, member) {
    let group = ws.joinGroups[groupId];
    if (group) {
        group.members[member.id].state = member.state;
    } else {
        console.warn('not join group', groupId, member);
    }
}

function setGroupMembers(groupId, members) {
    let group = ws.joinGroups[groupId];
    if (group) {
        group.members = members;
    } else {
        console.warn('not join group', groupId, members);
    }
}

function getGroupMembers(groupId, messageUid) {
    sendRTCMessage('group', 'getGroupMembers', '', groupId, messageUid);
}

function getMember(memberId, messageUid) {
    sendRTCMessage('server', 'getMember', '', memberId, messageUid);
}

function getMyExt(messageUid) {
    sendRTCMessage('server', 'getMyExt', '', 0, messageUid);
}

function updateMyExt(configs, contacts, extra) {
    configs = (configs !== null) ? JSON.stringify(configs) : configs;
    contacts = (contacts !== null) ? JSON.stringify(contacts) : contacts;
    extra = (extra !== null) ? JSON.stringify(extra) : extra;

    let updateExtMessage = { configs: configs, contacts: contacts, extra: extra };
    sendRTCMessage('server', 'updateMyExt', JSON.stringify(updateExtMessage), 0, Date.now());
}

function createMyCallHistory(eventType, callUid, members = [], group = '', shareMemberId = '', messageUid) {
    let extra = JSON.stringify({group});
    extra = JSON.parse(extra);
    extra.group.extra = JSON.stringify(extra.group.extra);
    extra = JSON.stringify(extra);
    console.log('extra!!!!!', extra)
    let callHistoryMessage = { eventType, callUid, members, extra, shareMemberId };
    console.log('callHistoryMessage!!!!!!!!', JSON.stringify(callHistoryMessage))
    sendRTCMessage('server', 'createMyCallHistory', JSON.stringify(callHistoryMessage), 0, messageUid);
}

function updateMyCallHistory(type, myHDbId, members = [], group = '', messageUid) {
    // extra = (extra !== null) ? JSON.stringify(extra) : extra;
    let extra = JSON.stringify({group});
    extra = JSON.parse(extra);
    extra.group.extra = JSON.stringify(extra.group.extra);
    extra = JSON.stringify(extra);
    let callHistoryMessage = { eventType: type, myHDbId: myHDbId, members: members, extra};
    sendRTCMessage('server', 'updateMyCallHistory', JSON.stringify(callHistoryMessage), 0, messageUid);
}

function getMyCallHistories(messageUid) {
    sendRTCMessage('server', 'getMyCallHistories', '', 0, messageUid);
}

function getMyCallHistory(myHDbId) {
    sendRTCMessage('server', 'getMyCallHistory', JSON.stringify({ myHDbId}), 0, '');
}

ws.init = function(apiHost, sign, me, joinGroups) {
    wssUri = apiHost + '/ws';
    ws.sign = sign;
    ws.me = me;
    ws.joinGroups = joinGroups;
};
ws.connectWebSocket = connectWebSocket;
ws.close = function() {
    webSocket.close();
};
ws.changePassword = function(oldPassword, newPassword) {
    let changePasswordMessage = { oldPassword, newPassword };
    sendRTCMessage('server', 'changePassword', JSON.stringify(changePasswordMessage), '0', Date.now());
};
ws.joinGroup = function(name, key, extra) {
    let joinGroupMessage = { name: name, key: key, extra: extra };
    sendRTCMessage('server', 'joinGroup', JSON.stringify(joinGroupMessage), '0', Date.now());
};
ws.leaveGroup = function(groupId) {
    let leaveGroupMessage = { id: groupId };
    sendRTCMessage('server', 'leaveGroup', JSON.stringify(leaveGroupMessage), '0', Date.now());
};
ws.updateGroup = function(groupId, name, extra) {
    let updateGroupMessage = { id: groupId, name: name, extra: extra };
    sendRTCMessage('server', 'updateGroup', JSON.stringify(updateGroupMessage), '0', Date.now());
};
ws.updateMe = function(me, extra, os, pushToken, modelNo, deviceUid) {
    let updateMeMessage;
    if (me) {
        updateMeMessage = { memberId: me.id || null, password: me.password || null, extra: extra || null, os: os, pushToken: pushToken, modelNo: modelNo, deviceUid: deviceUid, name: me.name, section: me.section, position: me.position, prfImgURL: me.prfImgURL };
    } else {
        updateMeMessage = { extra: extra, os: os, pushToken: pushToken, modelNo: modelNo, deviceUid: deviceUid };
    }
    sendRTCMessage('server', 'updateMe', JSON.stringify(updateMeMessage), '0', Date.now());
};
ws.updateProfileImg = function(name, content) {
    let updateProfileImgMessage = { name: name, content: content };
    sendRTCMessage('server', 'updateProfileImg', JSON.stringify(updateProfileImgMessage), '0', Date.now());
};
ws.updateStat = function(id, otherMemberId, type, startDT, endDT, durations) {
    let updateStatMessage = { id: id, otherMemberId: otherMemberId, type: type, startDT: startDT, endDT: endDT, durations: durations };
    sendRTCMessage('server', 'updateStat', JSON.stringify(updateStatMessage), '0', Date.now());
};
ws.updateMyExt = updateMyExt;
ws.getIceServers = function() {
    sendRTCMessage('server', 'getIceServers', '', '0', Date.now());
};
ws.getMyGroups = function() {
    sendRTCMessage('server', 'getMyGroups', '', '0', Date.now());
};
ws.sendMessageToMember = function(toId, messageType, message, messageUid) {
    sendRTCMessage('member', messageType, message, toId, messageUid);
};
ws.getGroupMembers = getGroupMembers;
ws.getMember = getMember;
ws.getMyExt = getMyExt;
ws.postState = postState;
ws.createMyCallHistory = createMyCallHistory;
ws.updateMyCallHistory = updateMyCallHistory;
ws.getMyCallHistories = getMyCallHistories;
ws.getMyCallHistory = getMyCallHistory;

ws.connectWebSocketFactory = function(token, isForce) {
    let reqUri = wssUri + '?IsForce=' + isForce + '&Token=' + encodeURIComponent(token);

    try {
        webSocket = new WebSocket(reqUri);
        setWebSocketEvent(webSocket);

        ws.webSocket = webSocket;
        return webSocket;
    } catch (e) {
        throw e;
    }


};

function createToken(sign) {
    try {
        let lastDotIdx = sign.lastIndexOf('.');
        if (lastDotIdx < 0) {
            console.warn('sign lastDotIdx < 0');
            return Promise.resolve(false);
        }
        let baseSignature = sign.substring(0, lastDotIdx);
        // console.info('baseSignature:' + baseSignature);

        let bsSplits = baseSignature.split('.');

        let bsBody64 = bsSplits[1];

        let bsBodyjson = b64DecodeUnicode(bsBody64);

        let bsBody = JSON.parse(bsBodyjson);
        let gid = bsBody.g;

        let iat = Math.floor(Date.now() / 1000);
        let planText = baseSignature + '.' + iat + '.' + window.location.hostname;
        let encrypted = commonService.encryptSha256Base64(planText);

        let token = 'v2.' + encrypted + '.' + gid + '.' + iat;

        return token;
    } catch (e) {
        console.error('error createToken', e);
        return '';
    }
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}