let signalingCoreService = require('./services/core');
let messageService = require('./services/message');
let commonService = require('./services/common');
let httpService = require('./services/http');
let wsService = require('./services/ws');

class Vivar {}


let factory = function(userConfig = {}) {
    let loc = window.location;
    let config;
    let wprotocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    let defaultConfig = {
        httpHost: window.location.protocol + '//' + loc.host,
        wsHost: wprotocol + '//' + loc.host,
        p2p: true,
        audio: true,
        video: {
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 }
        },
        sign: ''
    }

    config = Object.assign(defaultConfig, userConfig);

    let vivar = new Vivar();
    commonService.mergeEventEmitterPrototype(vivar, ['Error', 'Close', 'Login', 'Logout',
        'JoinGroup', 'NotifyJoinGroup',
        'LeaveGroup', 'NotifyLeaveGroup',
        'UpdateGroup', 'NotifyUpdateGroup',
        'UpdateMe', 'UpdateProfileImg', 'UpdateStat', 'UpdateState', 'UpdateMyExt', 'UpdateConnectionState',
        'NotifyUpdateMember', 'NotifyUpdateState',
        'GetGroupMembers', 'GetMember', 'GetGroups', 'GetMyGroups', 'GetMyExt',
        'SendMessageDataChannel', 'CallDisconnected', 'CallConnected', 'IncomingCall', 'AcceptCall', 'RejectCall', 'SignalingFail',
        'ReceiveMessage', 'ReceiveAppState', 'ReceiveFile', 'ReceiveDrawingCaptureFile', 'ReceiveDrawingClear',
        'ReceiveDrawingTracking', 'ReceiveARDrawing', 'ReceiveARDrawingAdd', 'ReceiveTrackingResult',
        'ReceiveARUndo', 'ReceiveARRemoveAll', 'ReceiveARRemove', 'ReceiveARFail', 'ReceiveColorChange',
        'RecvPCScreenDrawingCanvasPoint', 'RecvPCScreenDrawingAdd', 'RecvPCScreenDrawingRemove', 'RecvPCScreenDrawingRemoveAll', 'RecvPCScreenDrawingUndo',
        'UpdateMyCallHistory', 'CreateMyCallHistory', 'GetMyCallHistories', 'GetMyCallHistory', 'ChangePassword', 'ReceiveSwitchScreenShareReq', 'ReceiveSwitchScreenShareRes',
        'ReceiveSwitchComplete', 'ReceiveSwitchFail', 'ReceiveSwitchState'
    ]);

    let videoWidth = 1280; // vivar.config.remoteVideo.videoWidth;
    let videoHeight = 720; // vivar.config.remoteVideo.videoHeight;

    vivar.config = config;
    vivar.joinGroups = {};
    vivar.config.videoWidth = videoWidth;
    vivar.config.videoHeight = videoHeight;

    vivar.me = {
        'state': 'offline' // current state : alive, busy, lost, offline
    };
    signalingCoreService.setVivar(vivar);
    signalingCoreService.init();
    wsService.init(vivar.config.wsHost, vivar.config.sign, vivar.me, vivar.joinGroups);

    // 이벤트들
    httpService.onGetGroups = function(result, body) {
        vivar.trigger('GetGroups', [result, body]);
    };
    wsService.onGetIceServers = function(result, body) {
        if (!result) {
            vivar.trigger('Error', new Error('Did not get IceServers')); // TODO onError onNetworkError??
        } else {
            let servers = body;
            signalingCoreService.setIceServer(servers)
            vivar.trigger('Login', [result]);
        }
    };

    wsService.onError = function(error) {
        vivar.trigger('Error', [error]);
    };
    wsService.onClose = function(code, reason) {
        vivar.me.state = 'offline';
        wsService.postState();
        vivar.trigger('Close', [code, reason]);
    };
    wsService.onLogin = function(result) {
        vivar.trigger('Login', [result]);
    };
    wsService.onLogout = function(code, reason) {
        vivar.trigger('Logout', [code, reason]);
    };
    wsService.onChangePassword = function(result, code) {
        vivar.trigger('ChangePassword', [result, code]);
    };
    wsService.onJoinGroup = function(result, body) {
        vivar.trigger('JoinGroup', [result, body]);
    };
    wsService.onNotifyJoinGroup = function(groupId, member) {
        vivar.trigger('NotifyJoinGroup', [groupId, member]);
    };
    wsService.onLeaveGroup = function(result, body) {
        vivar.trigger('LeaveGroup', [result, body]);
    };
    wsService.onNotifyLeaveGroup = function(groupId, member) {
        vivar.trigger('NotifyLeaveGroup', [groupId, member]);
    };
    wsService.onUpdateGroup = function(result, body) {
        vivar.trigger('UpdateGroup', [result, body]);
    };
    wsService.onNotifyUpdateGroup = function(group) {
        vivar.trigger('NotifyUpdateGroup', [group]);
    };
    wsService.onUpdateMe = function(result, body) {
        vivar.trigger('UpdateMe', [result, body]);
    };
    wsService.onUpdateProfileImg = function(result, body) {
        vivar.trigger('UpdateProfileImg', [result, body]);
    };
    wsService.onUpdateStat = function(result, body) {
        vivar.trigger('UpdateStat', [result, body]);
    };
    wsService.onUpdateState = function(member) {
        vivar.trigger('UpdateState', [member]);
    };
    wsService.onUpdateMyExt = function(result, body) {
        vivar.trigger('UpdateMyExt', [result, body]);
    };
    wsService.onNotifyUpdateMember = function(groupId, member) {
        vivar.trigger('NotifyUpdateMember', [groupId, member]);
    };
    wsService.onNotifyClose = function(code, reason) {
        vivar.trigger('NotifyClose', [code, reason]);
    };
    wsService.onNotifyUpdateState = function(groupId, member) {
        vivar.trigger('NotifyUpdateState', [groupId, member]);
    };
    wsService.onGetGroupMembers = function(result, body) {
        vivar.trigger('GetGroupMembers', [result, body]);
    };
    wsService.onGetMember = function(result, body) {
        vivar.trigger('GetMember', [result, body]);
    };
    wsService.onGetMyGroups = function(result, body) {
        vivar.trigger('GetMyGroups', [result, body]);
    };
    wsService.onGetMyExt = function(result, body) {
        vivar.trigger('GetMyExt', [result, body]);
    };
    wsService.onCreateMyCallHistory = function(result, body) {
        vivar.trigger('CreateMyCallHistory', [result, body]);
    };
    wsService.onUpdateMyCallHistory = function(result, body) {
        vivar.trigger('UpdateMyCallHistory', [result, body]);
    };
    wsService.onGetMyCallHistories = function(result, body) {
        vivar.trigger('GetMyCallHistories', [result, body]);
    };
    wsService.onGetMyCallHistory = function(result, body) {
        vivar.trigger('GetMyCallHistory', [result, body]);
    };
    wsService.onSendMessageToMember = function(result, resultCode, messageUid) {
        // sdp, candidate, reject, endCall, ... 등의 메세지 전송 후 결과
        // TODO 같은 방에 없는 사용자에게 보내는 메세지 에러는 이쪽에서 발생한다. 어떻게 처리 할까?
        if (result === false) {
            vivar.trigger('Error', [new Error('SendMessageToMember Fail')]);
        }
    };

    wsService.onNotifyReceiveMessageFromMember = signalingCoreService.processSignalingProtocol

    return vivar;
};

module.exports = factory;