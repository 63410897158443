let peerService = require('./peer');
let helper = require('./helper');

function RTCConnectionManager(localUserId) {
    this.id = null;
    this.localUserId = localUserId;
    this.members = []; // 통화 상대.
    this.offerQueue = [];
    this.connections = {};
    this.currentRemoteUserId = '';
    this.group = {};
    this.iceServer = null;
    this.localVideo = null;
    this.localStream = null;
    this.localPCStream = null;
    this.recordingScreenStream = null;
    this.switchingTransactor = null;
    this.isReadytoCallConnect = false;
}

RTCConnectionManager.prototype.init = function() {

    Object.keys(this.connections).forEach((id) => {
        this.removeConnection(id);
    });

    this.id = null;
    this.offerQueue = [];
    this.currentRemoteUserId = '';
    this.members = [];

    // 모든 연결 끝났을때 로켈 카메라 끄기
    if (this.localStream && this.localStream.getTracks()) {
        let tracks = this.localStream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].stop();
        }
    }
    if (this.recordingScreenStream && this.recordingScreenStream.getTracks()) {
        let tracks = this.recordingScreenStream.getTracks();
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].stop();
        }
    }
    this.localStream = null;
    this.recordingScreenStream = null;

// if (this.localPCStream && this.localPCStream.getTracks()) {
//     let tracks = this.localPCStream.getTracks();
//     for (let i = 0; i < tracks.length; i++) {
//         tracks[i].stop();
//     }
//     this.localPCStream = null;
// }
    this.localVideo = null;
    this.switchingTransactor = null;
    this.isReadytoCallConnect = false;

};

RTCConnectionManager.prototype.setId = function(id) {
    this.id = id;
    return this;
};
RTCConnectionManager.prototype.getId = function() {
    return this.id;
};


RTCConnectionManager.prototype.createSwitchingTransactor = function(userId) {
    // helper객체에서 uuid가져옴
    const transactionId = Date.now() + "_" + helper.guid();
    // transaction 관리할 new Transactor 생성
    this.switchingTransactor = new Transactor(transactionId, userId);
    return this.switchingTransactor;
};
RTCConnectionManager.prototype.getSwitchingTransactor = function() {
    return this.switchingTransactor;
};


RTCConnectionManager.prototype.setLocalVideo = function (mediaStreamConstraints, localVideo) {

    if (!mediaStreamConstraints.video && !mediaStreamConstraints.audio) {
        return Promise.resolve();
    }

    return navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
        .then((stream) => {
            if(!mediaStreamConstraints.video) {
                // video false 이면 dummy videotrack 추가 -- audio track만 있으면 replace track 안됨
                let canvas = document.createElement("canvas");
                canvas.getContext('2d').fillRect(0, 0, 0, 0);
                let dummyStream = canvas.captureStream();
                let dummyVideoTrack = dummyStream.getVideoTracks()[0];
                stream.addTrack(dummyVideoTrack);
            }

            this.localStream = stream;
            this.localVideo = localVideo;
        });

};
RTCConnectionManager.prototype.getLocalVideo = function() {
    if (this.localVideo === null) {
        console.warn('[RTConnectionManager] - getLocalVideo is null', this)
    } else {
        return this.localVideo;
    }
};
RTCConnectionManager.prototype.removeConnection = function(id) {
    let index = this.members.findIndex((member) => {
        return parseInt(member.id) === parseInt(id);
    });
    if (index !== -1) {
        this.members.splice(index, 1);

        if (this.connections[id]) {
            this.connections[id].init();
            delete this.connections[id];
        }
    }

    return this;
};

RTCConnectionManager.prototype.add = function(connection) {
    if (connection instanceof RTCConnection) {
        this.connections[connection.remoteUserId] = connection;

    } else {
        console.warn(`${connection} it is not instance of RTCConnection`);
    }

    return this;
};

RTCConnectionManager.prototype.getConnection = function(remoteId) {
    let connection = null;

    if (remoteId) {
        this.currentRemoteUserId = parseInt(remoteId);
        connection = this.connections[parseInt(remoteId)];
    } else {
        connection = this.connections[this.currentRemoteUserId];
    }
    return connection;
};

RTCConnectionManager.prototype.getLocalStream = function() {
    if (this.localStream === null) {
        console.warn('[RTConnectionManager] - getlocalStream is null', this)
    } else {
        return this.localStream;
    }
};

RTCConnectionManager.prototype.getLocalPCStream = function() {
    if (this.localPCStream === null) {
        console.warn('[RTConnectionManager] - localPCStream is null', this)
    } else {
        return this.localPCStream.clone();
    }
};
RTCConnectionManager.prototype.getLocalAudioTrack = function() {
    if (this.localStream === null) {
        console.warn('[RTConnectionManager] - localStream is null', this)
    } else {
        return this.localStream.getAudioTracks()[0];
    }
};
RTCConnectionManager.prototype.getAudioTracksAll = function() {
    const audioTracks = [];
    audioTracks.push(this.localStream.getAudioTracks()[0]);
    Object.keys(this.connections).forEach((id) => {
        let stream = this.connections[id].getRemoteStream();
        // streams.push({id: stream});
        let audio = stream.getAudioTracks()[0];
        audioTracks.push(audio);
    });
    return audioTracks;
};

// workspace 추가
RTCConnectionManager.prototype.setGroup = function(workspace) {
    return this.group = workspace;
};
RTCConnectionManager.prototype.getGroup = function() {
    return this.group;
}

RTCConnectionManager.prototype.setMembers = function(members) {
    if (Array.isArray(members)) {
        members.forEach((member) => {
            this.setMember(member);
        });

    } else {
        this.setMember(members);
    }

    return this;
};
RTCConnectionManager.prototype.setMember = function(member) {
    this.currentRemoteUserId = member.id;
    let index = this.members.findIndex((item) => {
        return parseInt(item.id) === parseInt(member.id)
    });
    if (index === -1) {
        this.members = [...this.members, member];
    }

    return this;
};
RTCConnectionManager.prototype.getMembers = function() {
    return JSON.parse(JSON.stringify(this.members || null));
};
RTCConnectionManager.prototype.getMember = function(id) {
    let member = this.members.find((member) => {
        return parseInt(member.id) === parseInt(id);
    });
    return JSON.parse(JSON.stringify(member || null));
};
RTCConnectionManager.prototype.getCaller = function() {
    let member = this.members.find((member) => {
        return member.isCaller === true;
    });
    return JSON.parse(JSON.stringify(member || null));
};
RTCConnectionManager.prototype.getWorker = function() {
    let member = this.members.find((member) => {
        return member.isWorker === true;
    });
    return JSON.parse(JSON.stringify(member || null))
};
RTCConnectionManager.prototype.setWorker = function(workerId) {
    this.members.forEach((member) => {
        member.isWorker = parseInt(member.id) === parseInt(workerId);
    });
    return this;
};
RTCConnectionManager.prototype.setIceServer = function(server) {
    this.iceServer = server;
    return this;
};
RTCConnectionManager.prototype.getIceServer = function() {
    return this.iceServer;
};

RTCConnectionManager.prototype.addOfferQueue = function(remoteId) {
    this.offerQueue.push(remoteId);

    return this;
};

RTCConnectionManager.prototype.getNextOfferCycle = function() {
    return this.offerQueue.shift();
};
RTCConnectionManager.prototype.notify = function(message) {
    for (let key in this.connections) {
        let connection = this.connections[key];
        if (connection.sendMessageDataChannel) {
            if (connection.sendMessageDataChannel.readyState === 'open') {
                connection.sendMessageDataChannel.send(JSON.stringify(message));
            }
        }
    }
    return this;
};

/* 화면 공유자 변경 시 예외 상황을 위한 transaction_id를 관리할 transactor 클래스 생성 */
function Transactor(transactionId = '', userId = '') {
    this.transactionId = transactionId;
    this.oldTransactionId = transactionId;
    this.userId = userId;
    this.errorMessage = '';
}

// complete 까지 되면 newTransactor는 null로 초기화
Transactor.prototype.init = function() {
    this.transactionId = '';
}

function RTCConnection(id = '', remoteId = '') {
    this.id = parseInt(id);
    this.remoteUserId = parseInt(remoteId);
    this.peerClient = new peerService.PeerClient();
    this.callState = 'ready'; // ready(미통화), connected(연결 됐을때), disconnected(일시적으로 통화끊어 졌을때),  failed, closed(다시 연결 시도 실패했을때)
    this.isRemoteSDPSet = false;
    this.sendMessageDataChannel = null;
    this.sendFileDataChannel = null;
}
RTCConnection.prototype.init = function() {
    if (this.sendMessageDataChannel) {
        this.sendMessageDataChannel.close();
    }
    if (this.sendFileDataChannel) {
        this.sendFileDataChannel.close();
    }
    this.peerClient.init();
};

RTCConnection.prototype.setLocalVideo = function(localVideo) {
    this.peerClient.localVideo = localVideo;

    return this;
};

RTCConnection.prototype.setRemoteVideo = function(remoteVideo) {
    this.peerClient.remoteVideo = remoteVideo;

    return this;
};

RTCConnection.prototype.getRemoteSDP = function() {
    return this.peerClient.remoteSDP;
};
RTCConnection.prototype.setRemoteSDP = function(sdp) {
    if (sdp) {
        this.peerClient.remoteSDP = sdp;
        let remoteSessionDescription = new RTCSessionDescription(sdp);
        return this.peerClient.peer.setRemoteDescription(remoteSessionDescription);
    } else {
        if (this.peerClient.remoteSDP === null) {
            console.warn('[RTCConnection] - setRemoteSDP is null')
            return Promise.reject(false);
        } else {
            let remoteSessionDescription = new RTCSessionDescription(this.peerClient.remoteSDP);
            return this.peerClient.peer.setRemoteDescription(remoteSessionDescription);
        }
    }

};
RTCConnection.prototype.setLocalDescription = function(offerSDP) {
    return this.peerClient.peer.setLocalDescription(offerSDP);
};
RTCConnection.prototype.setPeerConnection = function(peer) {
    this.peerClient.peer = peer;
    return this;
};
RTCConnection.prototype.getPeerConnection = function() {
    return this.peerClient.peer;
};

RTCConnection.prototype.addLocalStream = function(stream) {
    if (stream) {
        this.peerClient.localStream = stream;
        this.peerClient.localStream.getTracks().forEach((track) => {
            this.peerClient.peer.addTrack(track, stream);
        });
    } else {
        console.warn('[RTCConnection] - addlocalStream is null', this)
    }

    return this;
};
RTCConnection.prototype.addLocalPCStream = function(stream) {
    const pcStream = stream;
    if (pcStream) {
        return Promise.all(this.peerClient.peer.getSenders().map(sender =>
            sender.replaceTrack(pcStream.getTracks().find(t => t.kind == sender.track.kind), pcStream)));
    } else {
        console.log('[addLocalPCStream] - stream is null')
        return Promise.reject()
    }
}
RTCConnection.prototype.addLocalAudioStream = function(stream) {
    if (stream) {
        this.peerClient.localStream = stream;
        this.peerClient.localStream.getAudioTracks().forEach((track) => {
            this.peerClient.peer.addTrack(track, stream);
        });
    } else {
        console.warn('[RTCConnection] - addlocalStream is null', this)
    }

    return this;
};
RTCConnection.prototype.addRemoteStream = function(stream) {
    if (this.peerClient.remoteVideo) {
        this.peerClient.remoteVideo.srcObject = null; // stream 초기화
        this.peerClient.remoteVideo.srcObject = new MediaStream();
        this.peerClient.remoteVideo.srcObject.addTrack(stream.getAudioTracks()[0]);
        // this.peerClient.remoteStream.push(stream);
        this.peerClient.remoteStream = stream;
        console.log('RemoteVideo Setted', stream);
    }

    return this;
};

RTCConnection.prototype.getLocalStream = function() {
    if (this.peerClient.localStream === null) {
        console.warn('[RTCConnection] - getlocalStream is null', this)
    } else {
        return this.peerClient.localStream;
    }
};
RTCConnection.prototype.getRemoteStream = function() {
    if (this.peerClient.remoteStream === null) {
        console.warn('[RTCConnection] - getRemoteStream is null', this)
    } else {
        return this.peerClient.remoteStream;
    }
};
RTCConnection.prototype.createOffer = function(successcallback, onfailure, sdpConstraints) {
    this.peerClient.peer.createOffer(successcallback, onfailure, sdpConstraints);

    return this;
};
RTCConnection.prototype.createAnswer = function(successcallback, onfailure, sdpConstraints) {
    this.peerClient.peer.createAnswer(successcallback, onfailure, sdpConstraints);

    return this;
};
RTCConnection.prototype.registerSDP = function(sdp) {
    this.peerClient.remoteSDP = sdp;
    return this;
};

RTCConnection.prototype.registerCandidate = function(candidate) {
    if (this.peerClient.peer && this.isRemoteSDPSet) {

        while (this.peerClient.remoteCandidates.length > 0) {
            let candidate_it = this.peerClient.remoteCandidates.pop();
            this.setRemoteCandidate(candidate_it);
        }

        if (candidate) {
            this.setRemoteCandidate(candidate);
        }

    } else {
        if (candidate) {
            this.peerClient.remoteCandidates.push(candidate);
        }
    }

    return Promise.resolve();
};

RTCConnection.prototype.setRemoteCandidate = function(candidate) {
    return this.peerClient.peer.addIceCandidate(new RTCIceCandidate(candidate));
};

module.exports = {
    RTCConnection: RTCConnection,
    RTCConnectionManager: RTCConnectionManager,
    Transactor: Transactor
};