function FileManager () {
    this.senderStreamId = 0;
    this.receiver= {};
    this.sender = {};
    this.handlers = {};
}
FileManager.prototype.registFileReceiver = function (fileReceiver) {
    console.log('registFileReceiver', this.receiver);
    this.receiver[fileReceiver.id] = fileReceiver;
}
FileManager.prototype.registFileSender = function (fileSender) {
    this.receiver[fileSender.id] = fileSender;
}
FileManager.prototype.getReceiver = function (id) {
    return this.receiver[id];
}
FileManager.prototype.getSender = function (id) {
    return this.sender[id];
}
FileManager.prototype.removeReceiver = function (id) {
    delete this.receiver[id];
}
FileManager.prototype.removeSender = function (id) {
    delete this.sender[id];
}
FileManager.prototype.getStreamId = function (id) {
    return ++this.senderStreamId;
}
FileManager.prototype.clearAll = function (id) {
    this.receiver = {};
    this.sender = {};
    this.handlers = {};
    this.senderStreamId = 0;
}
FileManager.prototype.ntohl = function (b, i) {
    return ((0xff & b[i]) << 24) |
        ((0xff & b[i + 1]) << 16) |
        ((0xff & b[i + 2]) << 8) |
        ((0xff & b[i + 3]));
}

FileManager.prototype.htonl = function (b, i, v) {
    b[i] = (0xff & (v >> 24));
    b[i + 1] = (0xff & (v >> 16));
    b[i + 2] = (0xff & (v >> 8));
    b[i + 3] = (0xff & (v));
}

FileManager.prototype.htons = function (b, i, v) {
    b[i] = (0xff & (v >> 8));
    b[i + 1] = (0xff & (v));
}

FileManager.prototype.ntohs = function (b, i) {
    return ((0xff & b[i]) << 8) |
        ((0xff & b[ i + 1]));
};

FileManager.prototype.bin2String = function (array) {
    var result = "";
    for (var i = 0; i < array.length; i++) {
        result += String.fromCharCode(array[i]);
    }
    return result;
}
FileManager.prototype.string2bin = function (str) {
    var bytes = [];
    for(var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        bytes.push(char);
    }
    return bytes;
}
FileManager.prototype.arrayBufferToBase64 = function (bytes) {
    var binary = '';
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}
FileManager.prototype.readSliceWhenProferBuffer = function (fileSender, f, d, onReadSlice, onError) {
    let file = f,
        dataChannel = d,
        DATA_CHUNK_SIZE = 1400,
        offset = 0,
        reader = new FileReader(),
        isLast = false;

    reader.onloadend = function () {
        if (reader.readyState !== FileReader.DONE) {
            return;
        }
        onReadSlice(reader.result, DATA_CHUNK_SIZE , reader.result.byteLength, offset, isLast);
        fileSender.sendFile(reader.result.byteLength, offset, reader.result)
        offset += reader.result.byteLength;
        isLast = file.size <= offset;
        if (!isLast) {
            seekWhenProferBuffer();
        }
    };
    reader.onerror = function (error) {
        onError(error);
    };
    seekWhenProferBuffer();

    function seekWhenProferBuffer() {
        if (dataChannel.bufferedAmount > 5242880) { // 버퍼 크기가 5MB 이상일때
            setTimeout(seekWhenProferBuffer, 500);
        } else {
            seek(offset, offset + DATA_CHUNK_SIZE);
        }
    }

    function seek(start, end) {
        var slice = file.slice(start, end);
        reader.readAsArrayBuffer(slice);
    }
};
FileManager.prototype.receiveFileOnDataChannel = function (event) {

    let byteArray = new Uint8Array(event.data);

    let lengthBuffer = byteArray.slice(0, 4);
    let offsetBuffer = byteArray.slice(4, 8);
    let identifierBuffer = byteArray.slice(10, 12);
    let payloadBuffer = byteArray.slice(12);

    let length = this.ntohl(lengthBuffer, 0);
    let offset = this.ntohl(offsetBuffer, 0);
    let type = byteArray[8];
    let reserved = byteArray[9];
    let identifier = this.ntohs(identifierBuffer, 0);
    let payload = payloadBuffer;
    let fileReceiver;
    if (type === 0) {
        // type 0 이면 header frame 이다.
        fileReceiver = new FileReceiver(identifier, JSON.parse(this.bin2String(payloadBuffer)));
        this.registFileReceiver(fileReceiver);
    } else if (type === 1) {
        fileReceiver = this.getReceiver(identifier);
        fileReceiver.add({length, offset, type, reserved, identifier, payload});
    }
    return fileReceiver;
}
FileManager.prototype.sendFileOnDataChannel = function (uploadFile, dataChannel, onReadSlice, onReadSliceError) {

    let streamId = this.getStreamId();
    const fileSender = new FileSender(streamId, dataChannel);
    fileSender.sendMeta({name: uploadFile.name, size: uploadFile.size, type: uploadFile.type, path: null, vType: 'NONE'});

    this.registFileSender(fileSender);

    this.readSliceWhenProferBuffer(fileSender, uploadFile, dataChannel, onReadSlice, onReadSliceError);

    return fileSender;
}


function FileTransportMeta (name, size, mimeType, vType, path) {
    this.name = name;
    this.size = size;
    this.mimeType = mimeType;
    this.vType = vType;
    this.path = path;
}

FileTransportMeta.prototype.getMetaBuffer = function () {
    var fileManager = new FileManager ();
    return fileManager.string2bin(JSON.stringify(this))
}

function FileFrameHeader (length, offset, type, reserved, identifier) {
    this.length = length;
    this.offset = offset;
    this.type = type;
    this.reserved = reserved;
    this.identifier = identifier;
}
FileFrameHeader.prototype.getFrameHeader = function () {
    var fileManager = new FileManager ();
    var byteArray = new Uint8Array(12);
    fileManager.htonl(byteArray, 0, this.length);
    fileManager.htonl(byteArray, 4, this.offset);
    byteArray[8] = this.type;
    byteArray[9] = this.reserved;
    fileManager.htons(byteArray, 10, this.identifier);

    return byteArray;
}


function FileObserver () {
    this.onProgress = null;
    this.onError = null;
    this.onCompleted = null;
}



function FileSender (id = 0, dataChannel, meta) {
    FileObserver.call(this);
    FileManager.call(this);
    // FileSender.call(this);
    this.id = id;
    this.meta = meta || {};
    this.sendSize = 0;
    this.dataChannel = dataChannel;
    this.state = 'init'; // init, progess, complete, fail
    this.bsendmeta = false;
    this.list = new LinkedList ();
}
FileSender.prototype = Object.create(FileManager.prototype);
FileSender.constructor = FileManager;
FileSender.prototype.setMeta = function (meta) {
    this.meta = meta;
};
FileSender.prototype.sendMeta = function  (fileInfo) {
    this.bsendmeta = true;
    this.state = 'progess';

    let name = fileInfo.name;
    let size = fileInfo.size;
    let type  = fileInfo.type;
    let mimeType;
    let path = fileInfo.path;
    let vType = fileInfo.vType;

    switch (type) {
        case 'image/jpeg' :
            mimeType = 'IMAGE'
            break;
        default :
            mimeType = 'IMAGE'
    }
    let fm = new FileTransportMeta (name, size, mimeType, vType, path);
    this.setMeta(fm);

    let fmBuffer = fm.getMetaBuffer();
    let fh = new FileFrameHeader(fmBuffer.length, 0, 0, 0, this.id);
    let fhBuffer = fh.getFrameHeader();
    let byteArray = new Uint8Array(fmBuffer.length + fhBuffer.length);
    byteArray.set(fhBuffer, 0);
    byteArray.set(fmBuffer, fhBuffer.length);

    this.dataChannel.send(byteArray)
};
FileSender.prototype.add = function (data) {

}
FileSender.prototype.sendFile = function  (length, offset, data) {
    if (this.bsendmeta) {

        let fh = new FileFrameHeader(length, offset, 1, 0, this.id);
        let fhBuffer = fh.getFrameHeader();
        let byteArray = new Uint8Array(length + fhBuffer.length);
        let byteDataArray = new Uint8Array(data);
        byteArray.set(fhBuffer, 0);
        byteArray.set(byteDataArray, fhBuffer.length);

        this.dataChannel.send(byteArray);

        this.sendSize += length;
        if (this.onProgress !== null) {
            this.onProgress(this.sendSize, this.meta.size);
        }
        if (this.meta.size === this.sendSize) {
            this.state = 'complete';
            if (this.onCompleted !== null) {
                this.onCompleted()
            }
        }
    }
};

function FileReceiver (id = 0, meta) {
    FileObserver.call(this);
    this.id = id;
    this.meta = meta;
    this.recvSize = 0;
    this.state = 'init'; // init, progess, complete, fail
    this.list = new LinkedList ();
}

FileReceiver.prototype.add = function (data) {
    let node = new Node(data);
    this.recvSize += node.length;
    this.state = 'progress';
    this.list.add(node);

    if (this.onProgress !== null) {
        this.onProgress(this.recvSize, this.meta.size);
    }
    if (this.meta.size === this.recvSize) {
        this.state = 'complete';
        if (this.onCompleted !== null) {
            this.onCompleted()
        }
    }
}

FileReceiver.prototype.isCompleted = function () {
    return (this.state === 'complete')
}
FileReceiver.prototype.toString = function () {
    return this.list.toString();
}
FileReceiver.prototype.getByteArray = function () {
    return this.list.toByteArray(this.meta.size);
}

function Node (data) {
    this.length = data.length;
    this.offset = data.offset;
    this.type = data.type;
    this.identifier = data.identifier;
    this.payload = data.payload;
    this.next = null;
}

function LinkedList() {
    this._head = null;
    this._length = 0;
}

LinkedList.prototype.add  = function (data) {
    let node = new Node(data);
    let prev;
    let curr;

    if (this._head === null) {
        this._head = node;
    } else {
        curr = this._head;

        if (curr.offset > node.offset){
            this._head = node;
            this._head.next = curr;
            return;
        }
        while (curr.next) {
            prev = curr;
            curr = curr.next;

            if (curr.offset > node.offset) {
                prev.next = node;
                node.next = curr;
                return
            }
        }

        if (curr.next === null) curr.next = node;

    }

    this._length ++;
}
LinkedList.prototype.toString = function () {
    let curr = this._head, str = '';
    while( curr ) {
        str += curr.payload;
        curr = curr.next;
    }
    return str;
};
LinkedList.prototype.toByteArray = function (size) {
    let curr = this._head,
        byteArray = new Uint8Array(size);

    while( curr ) {
        byteArray.set(curr.payload, curr.offset) ;
        curr = curr.next;
    }
    console.log(byteArray.length)
    return byteArray;
}


module.exports = {
    FileManager: FileManager,
    FileReceiver: FileReceiver,
    FileFrameHeader: FileFrameHeader,
    FileTransportMeta: FileTransportMeta,
    FileSender: FileSender,
};
