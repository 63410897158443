var EventEmitter = require('wolfy87-eventemitter');
var CryptoJS = require('crypto-js');

function encryptSha256Base64 (planText) {
    var encrypted = CryptoJS.SHA256(planText);
    return encrypted.toString(CryptoJS.enc.Base64);
}

function setCookie (cname, cvalue, exseconds) {
    var d = new Date();
    d.setSeconds(d.getSeconds() + exseconds);
    var expires = 'expires=' + d.toGMTString();
    document.cookie = cname + '=' + cvalue + '; ' + expires;
};
function reviver (key, val) {
    if (key && (Number(key) !== key) && (key.charAt(0) !== key.charAt(0).toLowerCase())) {
        this[key.charAt(0).toLowerCase() + key.slice(1)] = val;
    } else {
        return val;
    }
}
function jsonParse (json) {
    // camelCase Convert
    return JSON.parse(json, reviver);
}
// EventEmitter의 Prototype을 merger하고 registerEvent를 호출하여 이벤트를 property로 등록까지 해서 생성 해 줌.
function mergeEventEmitterPrototype (object, eventNameArray) {
    let eeProto = Object.clone(EventEmitter.prototype);
    let objPrototype = Object.getPrototypeOf(object);
    Object.merge(eeProto, objPrototype);
    Object.setPrototypeOf(object, eeProto);

    registerEvent(object, eventNameArray);
}

// 이벤트를 on 형태로 하여 사용하기 쉽게 등록 할 수 있도록 property로 등록 해 줌.
function registerEvent (object, eventNameArray) {
    eventNameArray.forEach((method) => {
        Object.defineProperty(Object.getPrototypeOf(object), `on${method}`, {
            /**
             * Return the listener of the event.
             *
             * @return {(Function|undefined)} The event listener or `undefined`
             * @public
             */
            get () {
                const listeners = this.getListeners(method);
                for (var i = 0; i < listeners.length; i++) {
                    if (listeners[i]._listener) return listeners[i]._listener;
                }
            },
            /**
             * Add a listener for the event.
             *
             * @param {Function} listener The listener to add
             * @public
             */
            set (listener) {
                const listeners = this.getListeners(method);
                for (var i = 0; i < listeners.length; i++) {
                    //
                    // Remove only the listeners added via `addEventListener`.
                    //
                    if (listeners[i]._listener) this.removeListener(method, listeners[i]);
                }
                this.addListener(method, listener);
            }
        });
    });
}

function checkMediaDevice (callback) {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        // Firefox 38+ seems having support of enumerateDevicesx
        navigator.enumerateDevices = function(callback) {
            navigator.mediaDevices.enumerateDevices().then(callback);
        };
    }

    var MediaDevices = [];
    var isHTTPs = location.protocol === 'https:';
    var canEnumerate = false;

    if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
        canEnumerate = true;
    } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
        canEnumerate = true;
    }

    var hasMicrophone = false;
    var hasSpeakers = false;
    var hasWebcam = false;

    var isMicrophoneAlreadyCaptured = false;
    var isWebcamAlreadyCaptured = false;

    if (!canEnumerate) {
        return;
    }

    if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
    }

    if (!navigator.enumerateDevices && navigator.enumerateDevices) {
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
    }

    if (!navigator.enumerateDevices) {
        if (callback) {
            callback();
        }
        return;
    }

    MediaDevices = [];
    navigator.enumerateDevices(function(devices) {
        devices.forEach(function(_device) {
            var device = {};
            for (var d in _device) {
                device[d] = _device[d];
            }

            if (device.kind === 'audio') {
                device.kind = 'audioinput';
            }

            if (device.kind === 'video') {
                device.kind = 'videoinput';
            }

            var skip;
            MediaDevices.forEach(function(d) {
                if (d.id === device.id && d.kind === device.kind) {
                    skip = true;
                }
            });

            if (skip) {
                return;
            }

            if (!device.deviceId) {
                device.deviceId = device.id;
            }

            if (!device.id) {
                device.id = device.deviceId;
            }

            if (!device.label) {
                device.label = 'Please invoke getUserMedia once.';
                if (!isHTTPs) {
                    device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
                }
            } else {
                if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
                    isWebcamAlreadyCaptured = true;
                }

                if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
                    isMicrophoneAlreadyCaptured = true;
                }
            }

            if (device.kind === 'audioinput') {
                hasMicrophone = true;
            }

            if (device.kind === 'audiooutput') {
                hasSpeakers = true;
            }

            if (device.kind === 'videoinput') {
                hasWebcam = true;
            }

            // there is no 'videoouput' in the spec.

            MediaDevices.push(device);
        });

        if (callback) {
            callback({
            hasMicrophone,
            hasSpeakers,
            hasWebcam,
            isMicrophoneAlreadyCaptured,
            isWebcamAlreadyCaptured,
            });
        }
    });

}

module.exports = {
    EventEmitter: EventEmitter,
    setCookie: setCookie,
    jsonParse: jsonParse,
    mergeEventEmitterPrototype: mergeEventEmitterPrototype,
    registerEvent: registerEvent,
    encryptSha256Base64: encryptSha256Base64,
    checkMediaDevice: checkMediaDevice
};
