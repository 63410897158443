/**
 * Created by front on 2019-10-29.
 */
let helper = require('./helper');


let RTCMessage = (function() {
    let vivar = null;
    let dcMessages = {};

    function RTCMessage() {}

    RTCMessage.prototype.setVivar = (vivarService) => {
        vivar = vivarService;
    };
    RTCMessage.prototype.createDcMessage = (uuid, name, contentType, content, transferEncoding, chunkIndex, isLastChunk) => {
        let dcMessage = {};
        dcMessage.uuid = uuid;
        dcMessage.name = name;
        dcMessage.contentType = contentType;
        dcMessage.content = content;
        dcMessage.transferEncoding = transferEncoding;
        dcMessage.chunkIndex = chunkIndex;
        dcMessage.isLastChunk = isLastChunk;
        return dcMessage;
    };
    RTCMessage.prototype.createDcMessages = (fullContent, name, contentType) => {
        let uuid = helper.guid();
        let chunkSize = 3 * 10240;

        if (fullContent.length > chunkSize) {
            let chunkMax = Math.ceil(fullContent.length / chunkSize);
            for (let i = 0; i < chunkMax; i++) {
                let dcMessage = this.createDcMessage(uuid, name, contentType, fullContent.substring(i * chunkSize, i * chunkSize + chunkSize), 'chunked', i, !(i + 1 < chunkMax));

                let chunkIndexKey = 'i_' + dcMessage.chunkIndex;
                dcMessages[chunkIndexKey] = dcMessage;
            }
        } else {
            let dcMessage = this.createDcMessage(uuid, name, contentType, fullContent, 'chunked', 0, true);

            let chunkIndexKey = 'i_' + dcMessage.chunkIndex;
            dcMessages[chunkIndexKey] = dcMessage;
        }

        return dcMessages;
    };
    RTCMessage.prototype.handleRemoteDataChannelOnMessage = function(event) {
        console.log('receiveMessageDataChannel, onmessage');
        let dcMessage = JSON.parse(event.data);
        if (dcMessage && dcMessage.uuid) {
            let uuidKey = 'u_' + dcMessage.uuid;
            let dcContents = dcMessages[uuidKey];
            if (!dcContents) {
                dcContents = dcMessages[uuidKey] = {};
                dcContents.count = 0;
            }

            let chunkIndexKey = 'i_' + (dcMessage.chunkIndex || 0);
            dcContents[chunkIndexKey] = dcMessage;
            dcContents.count++;

            if (dcMessage.isLastChunk || dcMessage.chunkIndex === null || dcMessage.chunkIndex === undefined) {
                dcContents.last = dcMessage;
            }

            if (dcContents.last) {
                if (dcContents.count === (dcContents.last.chunkIndex + 1)) {
                    let fullContent = this.restoreChunkedMessage(dcContents);
                    dcContents = null;
                    delete dcMessages[uuidKey];

                    if (dcMessage.contentType.startsWith('text')) {
                        vivar.trigger('ReceiveMessage', [fullContent]);
                    } else if (dcMessage.contentType === 'application/mint') {
                        this.processDataChanelMessage(fullContent);
                    } else {
                        console.warn('datachennel message protocol unmatched : ', event.data);
                    }
                } else if (dcContents.count > (dcContents.last.chunkIndex + 1)) {
                    console.warn('datachennel message count abnormal : ', dcContents.count, ' > ', dcContents.last.chunkIndex + 1);
                }
            }
        } else {
            console.warn('datachennel message protocol unmatched : ', event.data);
        }
    };
    RTCMessage.prototype.restoreChunkedMessage = (dcContents) => {
        let fullContent = '';
        for (let i = 0; i <= dcContents.last.chunkIndex; i++) {
            let chunkIndexKey = 'i_' + i;
            let dcMessage = dcContents[chunkIndexKey];
            fullContent = fullContent + dcMessage.content;
        }
        return fullContent;
    };
    RTCMessage.prototype.processDataChanelMessage = (fullContent) => {
        let maxstContent = JSON.parse(fullContent);
        let fromId = maxstContent.id;
        if (maxstContent.type === 'Command') {
            console.warn('Command not Processed : ', maxstContent);
        } else if (maxstContent.type === 'Tracking') {
            let trackingResult = JSON.parse(maxstContent.data);

            if (trackingResult.messageType === 'TrackingResult') {
                vivar.trigger('ReceiveTrackingResult', [trackingResult.poseMatrix]);
            }
        } else if (maxstContent.type === 'InteractionAr') {
            let InteractionArResult = JSON.parse(maxstContent.data);

            if (InteractionArResult.messageType === 'DrawingAdd' || InteractionArResult.messageType === 'StickerAdd' || InteractionArResult.messageType === 'LabelAdd') { // 상대 또는 내가 drawing 했을때.
                vivar.trigger('ReceiveARDrawingAdd', [InteractionArResult]);
            } else if (InteractionArResult.messageType === 'InteractionArRemove') {
                vivar.trigger('ReceiveARRemove', [InteractionArResult]);
            } else if (InteractionArResult.messageType === 'InteractionArRemoveAll') {
                vivar.trigger('ReceiveARRemoveAll', [InteractionArResult]);
            } else if (InteractionArResult.messageType === 'InteractionArUndo') {
                vivar.trigger('ReceiveARUndo', [InteractionArResult]);
            } else if (InteractionArResult.messageType === 'InteractionArFail') {
                vivar.trigger('ReceiveARFail', [InteractionArResult]);
            } else if (InteractionArResult.messageType === 'PersonalColorChange') {
                vivar.trigger('ReceiveColorChange', [InteractionArResult]);
            }
        } else if (maxstContent.type === 'InteractionPCScreen') {
            let InteractionPCScreenResult = JSON.parse(maxstContent.data);

            if (InteractionPCScreenResult.messageType === 'PCScreenDrawingCanvasPoint') {
                vivar.trigger('RecvPCScreenDrawingCanvasPoint', [InteractionPCScreenResult]);
            } else if (InteractionPCScreenResult.messageType === 'PCScreenDrawingAdd' || InteractionPCScreenResult.messageType === 'PCScreenStickerAdd') { // 상대 또는 내가 drawing 했을때.
                vivar.trigger('RecvPCScreenDrawingAdd', [InteractionPCScreenResult]);
            } else if (InteractionPCScreenResult.messageType === 'PCScreenDrawingRemove') {
                vivar.trigger('RecvPCScreenDrawingRemove', [InteractionPCScreenResult]);
            } else if (InteractionPCScreenResult.messageType === 'PCScreenDrawingRemoveAll') {
                vivar.trigger('RecvPCScreenDrawingRemoveAll', [InteractionPCScreenResult]);
            } else if (InteractionPCScreenResult.messageType === 'PCScreenDrawingUndo') {
                vivar.trigger('RecvPCScreenDrawingUndo', [InteractionPCScreenResult]);
            }
        } else if (maxstContent.type === 'State') {
            let maxstContentData = JSON.parse(maxstContent.data);
            let valid = true;
            if (maxstContentData.messageType === 'App') {
                if (!(maxstContentData.state === 'Foreground' || maxstContentData.state === 'Background' || maxstContentData.state === 'Underground')) {
                    valid = false;
                }
            } else if (maxstContentData.messageType === 'Video' || maxstContentData.messageType === 'Audio') {
                if (!(maxstContentData.state === 'Enable' || maxstContentData.state === 'Disable')) {
                    valid = false;
                }
            } else if (maxstContentData.messageType === 'Recording') {
                if (!(maxstContentData.state === 'Enable' || maxstContentData.state === 'Disable')) {
                    valid = false;
                }
            } else {
                valid = false;
            }
            if (valid === true) {
                vivar.trigger('ReceiveAppState', [maxstContentData.userId, maxstContentData.messageType, maxstContentData.state.toLowerCase()]);
            } else {
                console.warn('State not Processed : ', maxstContent);
            }
        } else if (maxstContent.type === 'ScreenShare') {
            let maxstContentData = JSON.parse(maxstContent.data);
            if (maxstContentData.messageType === 'SwitchReq') {
                vivar.trigger('ReceiveSwitchScreenShareReq', [maxstContentData]);
            } else if (maxstContentData.messageType === 'SwitchRes') {
                vivar.trigger('ReceiveSwitchScreenShareRes', [maxstContentData]);
            } else if (maxstContentData.messageType === 'SwitchComplete') {
                vivar.trigger('ReceiveSwitchComplete', [maxstContentData]);
            } else if (maxstContentData.messageType === 'SwitchFail') {
                vivar.trigger('ReceiveSwitchFail', [maxstContentData]);
            } else if (maxstContentData.messageType === 'State') {
                vivar.trigger('ReceiveSwitchState', [maxstContentData]);
            }
        } else if (maxstContent.type === 'Direction') {
            console.warn('Direction not Processed : ', maxstContent);
        } else if (maxstContent.type === 'Trackable') {
            console.warn('Trackable not Processed : ', maxstContent);
        } else if (maxstContent.type === 'Manual') {
            console.warn('Manual not Processed : ', maxstContent);
        } else if (maxstContent.type === 'Chat') {
            vivar.trigger('ReceiveMessage', [JSON.parse(maxstContent.data)]);
        } else {
            console.warn('datachennel message maxstContent Type unmatched : ', maxstContent);
        }
    };
    return new RTCMessage();
})();

module.exports = RTCMessage;